import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { isMobile } from '../../functions/theme';

// --- ABOUT IMAGES COMPONENT ---
    // usBigImages --> Array of images from strapi
const AboutImages = (props: {usBigImages: any} | null) => {

    const _isMobile: boolean = isMobile();

    return (
        <>
            <div className="flex flex-col items-center w-screen h-screen bg-black">
                <div className='flex items-end w-full px-5 pb-2 text-3xl font-bold lg:pb-0 lg:items-center text-cardinal-white md:text-4xl lg:text-5xl xl:text-7xl h-1/5 lg:h-1/6 lg:px-36'>The team</div>

                {/* --- START | Image Slider --- */}
                {/* --- 1st div double the width of what you need | If w-screen (100%) then w-[200%] | STATIC --- */}
                <div className='w-[200%] h-2/3 relative overflow-hidden'>
                    {/* --- 2nd div same width of parent | Absolute to let it slide (animation --> imageSlider [global.scss]) | left to align --- */}
                    <div className='absolute left-1/4 flex justify-around w-[200%] h-full gap-4 imageSlider'>
                        {/* --- Two copies of what will slide | It will reset automatically when half of the the main div width is reached --- */}
                        {/* --- 1st Array of images or elements to slide --- */}
                        { 
                            props?.usBigImages && props.usBigImages.sort((a: any, b: any) => a.name > b.name ? 1 : -1).map((el: any) => {
                                const image: IGatsbyImageData = el?.localFile && getImage(el.localFile);
                                return (
                                        <div className='flex items-center justify-center w-full h-full' key={el?.id + 'container'}>
                                            <GatsbyImage image={image} alt={el?.id} className='h-full'/>
                                        </div>
                                )
                            })
                        }
                        {/* --- 2nd Array of images or elements to slide act as the buffer --- */}
                        { 
                            props?.usBigImages && props.usBigImages.sort((a: any, b: any) => a.name > b.name ? 1 : -1).map((el: any) => {
                                const image: IGatsbyImageData = el?.localFile && getImage(el.localFile);
                                return (
                                        <div className='flex items-center justify-center w-full h-full' key={el?.id + 'container'}>
                                            <GatsbyImage image={image} alt={el?.id} className='h-full'/>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* --- END | Image Slider --- */}
                
            </div>
        </>
    )

}

export default AboutImages;